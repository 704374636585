
import { defineComponent, ref, onMounted, reactive, watch, nextTick, computed } from "vue";
import { ElInput } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import { useStore } from "vuex";
import * as moment from "moment";
import { Actions } from "@/store/enums/StoreEnums";

import BordPDF from "@/views/still/bord/pdf.vue"; 

import mAxiosApi from "@/api";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "Overview",
  components: {
    BordPDF
  },
  
  setup() {

    const store = useStore();
    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    //moment.default.locale("fr");
    const state = reactive({
      bor_n_seq : parseInt(localStorage.getItem("currentBord") || "0"),
      bordereau: {} as any,
      loaderEnabled: true,
      initialMyList: [],
      loadingDatatable: 0,
      lUsers: [] as any,
      basculeBord: '',
      typeBord: 0,
    });


    const form = reactive({
      bor_c_sort_versement : '',
      bor_c_ref_reglementaire: '',
      bor_n_delai_communicabilite: '',
      bor_d_communicabilite: '' as any,
      bor_n_dua: '',
      bor_c_keyword: [] as any,
      bor_n_seq : 0,
      bor_c_service_versant: '',
      bor_c_direction: '',
      bor_c_sous_direction: '',
      bor_knum_utilisateur : 0,
      bor_knum_archiviste : 0,
      bor_knum_chef_service : 0 as any,
      bor_knum_direction_archive: 0 as any,
      bor_n_nb_article_elimination:0,
      bor_c_annee_mini : '',
      bor_c_annee_maxi : '',
      bor_n_papier_metrage : ''
    })

    const tableHeader = ref([
      { name: "N° d'ordre", key: "art_n_num_ordre", sortable: false,},
      { name: "Titre", key: "art_c_categorie", sortable: false,},
      { name: "Libellé", key: "art_c_libelle", sortable: false,},
      { name: "Dates", key: "art_n_annee_mini", sortable: false,},
      { name: "DUA", key: "art_n_dua", sortable: false,},
      { name: "Observations", key: "art_c_observations", sortable: false,},
      { name: "", key: "art_n_seq", sortable: false,},

    ]);

    const tableFilters = ref([
      { name: "titre", key: "art_c_categorie", label: "art_c_categorie", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang);
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const totArticle = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/getSVArticles/" + parseInt(localStorage.getItem("currentBord") || "0"));
      form.bor_n_nb_article_elimination = myListLiv.results.length;
    };    

    const inputValue = ref('')
    const inputVisible = ref(false)
    const InputRef = ref<InstanceType<typeof ElInput>>()

    const handleClose = (tag: string) => {
      form.bor_c_keyword.splice(form.bor_c_keyword.indexOf(tag), 1)
    }

    const showInput = () => {
      inputVisible.value = true
      nextTick(() => {
        if (InputRef.value) InputRef.value.input.focus()
      })
    }

    const handleInputConfirm = () => {
      if (inputValue.value) {
        form.bor_c_keyword.push(inputValue.value)
      }
      inputVisible.value = false
      inputValue.value = ''
    }

    watch(form, () => {
      editBordereau();
    });

    const switchTypeBord = async () => {

    const newBord = form.bor_c_sort_versement == 'Conservation intégrale' || form.bor_c_sort_versement == 'Conservation partielle' ? 'Elimination' : 'Conservation intégrale'

    let textDisplay = "Souhaitez-vous réellement basculer ce bordereau en bordereau de ";
    textDisplay += form.bor_c_sort_versement == 'Conservation intégrale' || form.bor_c_sort_versement == 'Conservation partielle' ? "d'élimination ?" : 'de versement ?';
    
    ElMessageBox.confirm(
      textDisplay, 'Confirmation',
      {
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        type: '',
      }
    )
      .then(() => {
        let newObj = JSON.parse(JSON.stringify(form))
        newObj.bor_c_sort_versement = newBord;

        const currentBordType = newObj.bor_c_sort_versement == 'Conservation intégrale' || newObj.bor_c_sort_versement == 'Conservation partielle' ? 'versement' : 'élimination'
        localStorage.setItem("currentBordType", currentBordType);
        store.dispatch(Actions.SET_CURRENT_BORD, {bordSeq: newObj.bor_n_seq, bordType : currentBordType });      

        mAxiosApi
          .post("/editBord/", newObj)
          .then(() => {
            window.location.reload();
          })
      })
      .catch(() => {
        // Annulé
      })


    }
    
    const editBordereau = () => {
      form.bor_d_communicabilite = form.bor_d_communicabilite ? moment.default(form.bor_d_communicabilite).format("YYYY-MM-DD") : null;
      form.bor_knum_chef_service = form.bor_knum_chef_service ? form.bor_knum_chef_service : null;
      form.bor_knum_direction_archive = form.bor_knum_direction_archive ? form.bor_knum_direction_archive : null;
      if (form.bor_n_seq> 0 ) mAxiosApi.post("/editBord", form)
    };

    onMounted(async () => {

      const myListUsers = await getAxios("/getSVUsers");
      state.lUsers = myListUsers.results;

      const myListLiv = await getAxios("/getSVoneBord/"+ parseInt(localStorage.getItem("currentBord") || "0"));
      const res = myListLiv.results;
      state.bordereau = myListLiv.results

      form.bor_c_sort_versement = res.bor_c_sort_versement;
      form.bor_c_ref_reglementaire = res.bor_c_ref_reglementaire;
      form.bor_n_delai_communicabilite = res.bor_n_delai_communicabilite;
      form.bor_d_communicabilite = res.bor_d_communicabilite;
      form.bor_n_dua = res.bor_n_dua;
      form.bor_c_keyword = res.bor_c_keyword ? res.bor_c_keyword.split("||") : [];
      form.bor_c_service_versant= res.bor_c_service_versant;
      form.bor_c_direction= res.bor_c_direction;
      form.bor_c_sous_direction= res.bor_c_sous_direction;
      form.bor_knum_utilisateur = res.bor_knum_utilisateur;
      form.bor_knum_archiviste = res.bor_knum_archiviste;
      form.bor_knum_chef_service = res.bor_knum_chef_service;
      form.bor_knum_direction_archive = res.bor_knum_direction_archive;
      form.bor_n_nb_article_elimination = res.bor_n_nb_article_elimination;
      form.bor_c_annee_mini = res.bor_c_annee_mini;
      form.bor_c_annee_maxi = res.bor_c_annee_maxi;
      form.bor_n_seq = res.bor_n_seq;
      form.bor_n_papier_metrage = res.bor_n_papier_metrage;
      
      state.basculeBord = form.bor_c_sort_versement == 'Conservation intégrale' || form.bor_c_sort_versement == 'Conservation partielle' ? "d'élimination" : 'de versement';
      state.typeBord = form.bor_c_sort_versement == 'Conservation intégrale' || form.bor_c_sort_versement == 'Conservation partielle' ? 1 : 2;

      state.loaderEnabled = false;
    });

    const anneesExtremes = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/getSVArticlesMaxAnnees/" + parseInt(localStorage.getItem("currentBord") || "0"));
      //console.log(myListLiv);
      form.bor_c_annee_mini = myListLiv.results.art_n_annee_mini;
      form.bor_c_annee_maxi = myListLiv.results.art_n_annee_maxi;
    };


    const totMetrage = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/getSVArticlesML/" + parseInt(localStorage.getItem("currentBord") || "0"));
      form.bor_n_papier_metrage = Math.round(myListLiv.results.art_metrage_lineaire * 100) / 100 as any;
    };

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      form,
      handleClose,
      showInput,
      handleInputConfirm,
      inputValue,
      inputVisible,
      InputRef,
      editBordereau,
      pageTitle,
      totArticle,
      anneesExtremes,
      switchTypeBord,
      totMetrage
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

